import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Checkbox, Container, Typography } from "@mui/material";
import pdfimg from "./pdf.png";
import {Box} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4D4F50",
    color: " #F7F7F7",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const styles = {
  billingHistory:{
    fontWeight:'700',
    fontSize:'30px '
  },

  button: {
    borderRadius: " 5px",
    background: "#00C6FF",
    width: "101px",
    height: "30px",
    "&:hover": {
      background: "#00C6FF",
    },
    border: "none",
    "@media(max-width:480px)":{
      width: "80px",
      height: "25px",
      fontSize:"10px"
    }
  },
  tablecell: {
    paddingLeft: "0px",
    // paddingRight: "10rem",
    paddingBottom: "1px",
    paddingTop: "1px",
    margin: "10px",
    // display: "flex",
    // justifyContent: "center",
  },
  tablehead: {
    paddingLeft: "0",

    "&:first-child": {
      paddingLeft: "30px",
    },
  },
    pdfIcon: {
    width: "20px",
    height: "20px",
    '@media (max-width: 480px)': {
      display: 'none',
    },
  },
};

function createData(name, Billing_Date, Amount, Plan, button) {
  return { name, Billing_Date, Amount, Plan, button };
}

const rows = [
  createData(
    "Invoice #001 - Nov 2023",
    159,
    6.0,
    24,
    <Button variant="contained" sx={styles.button}>
      Download
    </Button>
  ),
  createData(
    "Invoice #001 - Nov 2023",
    237,
    9.0,
    37,
    <Button variant="contained" sx={styles.button}>
      Download
    </Button>
  ),
  createData(
    "Invoice #001 - Nov 2023",
    262,
    16.0,
    24,
    <Button variant="contained" sx={styles.button}>
      Download
    </Button>
  ),
  createData(
    "Invoice #001 - Nov 2023",
    305,
    3.7,
    67,
    <Button variant="contained" sx={styles.button}>
      Download
    </Button>
  ),
  createData(
    "Invoice #001 - Nov 2023",
    356,
    16.0,
    49,
    <Button variant="contained" sx={styles.button}>
      Download
    </Button>
  ),
];

export default function SubcrptionHistory() {
  return (
    
    <Box style={{  width:"80%" , margin:'0 auto'}}> 
      <Typography sx={styles.billingHistory}>
        Billing History
      </Typography>
      <Typography>
        Download your previous plan receipts and details.
      </Typography>
    <TableContainer  >
      <Table aria-label="customized table">
        <TableHead sx={{ borderRadius: "5px" }}>
          <TableRow>
            <StyledTableCell align="left" sx={styles.tablehead}>
              Invoice
            </StyledTableCell>
            <StyledTableCell align="left" sx={styles.tablehead}>
              Billing Date
            </StyledTableCell>
            <StyledTableCell align="left"  sx={styles.tablehead}>
              Amount
            </StyledTableCell>
            <StyledTableCell align="left" sx={styles.tablehead}>
              Plan
            </StyledTableCell>
            <StyledTableCell
              align="left"
              sx={styles.tablehead}
            ></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow sx={{ borderRadius: "5px" }} key={row.name}>
              <StyledTableCell component="th" scope="row" sx={styles.tablecell}>
              <Box sx={{ display: "flex"}}>
              {<Checkbox />}
                <Box sx={{ display: "flex" , marginTop:"12px",width:"40%",justifyContent:"space-around"}}>
                
                <img
                  src={pdfimg}
                  alt="pdf icon"
                  style={{ 
                    width: "20px",
                    height: "20px",
                  }}
                  className="hideOnMobile"

                       />
                {row.name}
                </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell align="left" sx={styles.tablecell}>
                {row.Billing_Date}
              </StyledTableCell>
              <StyledTableCell align="left" sx={styles.tablecell}>
                {row.Amount}
              </StyledTableCell>
              <StyledTableCell align="left" sx={styles.tablecell}>
                {row.Plan}
              </StyledTableCell>

              <StyledTableCell align="right" sx={styles.tablecell}>
                {row.button}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  
  );
}
