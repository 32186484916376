import {
  Container,
  Grid,
  Button,
  Typography,
  Stack,
  Box,
  Link,
} from "@mui/material";
import React from "react";
import logo from "./image/logo.png";
import google from "./image/image 26.png";
import facebook from "./image/image 34.png";
import apple from "./image/Page-1.png";
import styled from "styled-components";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const styles = {
    headingTypo:{
        color: "rgba(0, 0, 0, 0.87",
        fontFamily: "Nunito",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        // lineHeight: "normal",
        textTransform:"uppercase",
        marginBottom:"3rem"
    },
  typoLable: {
    // ... other styles
    
        // ... other styles
        "& .MuiFormControlLabel-label": {
          // Target the label of the FormControlLabel
          color: "#29A0C2",
          fontFamily: "Nunito",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          textTransform:"capitalize"
        }
      
    },
    buttonText:{
        color: "#262626",
        fontFamily: "Nunito",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        textTransform:"capitalize",
        "@media (max-width:480px)":{
          fontSize: "12px",
        }
        
    }
}
const StyledButton = styled(Button)`
borderRadius: 5px;
border: 1px solid #E7E7E7;
  margin-top: 8px;
  width: 100%; // Adjust as needed
`;

const ButtonContent = styled(Box)`
  display: flex;
  width: 70%;
`;

const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;

  @media (max-width: 480px) {
  
    width: 40%; 
    justify-content: flex-start;
  }
`;

const IconImage = styled.img` 
  width: 25px;
  margin-left: 2rem;
`;

const TextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
`;
const AuthButton = ({ type }) => {
  let src, text;

  switch (type) {
    case "google":
      src = google;
      text = "Continue With Google";
      break;
    case "facebook":
      src = facebook;
      text = "Continue With Facebook";
      break;
    case "apple":
      src = apple;
      text = "Continue With Apple";
      break;
    default:
      src = "";
      text = "";
  }

  return (
    <StyledButton variant="outlined">
      <ButtonContent>
        <IconWrapper>
          <IconImage src={src} alt={`${text} Icon`} />
        </IconWrapper>
        <TextWrapper>
          <Typography sx={styles.buttonText}>{text}</Typography>
        </TextWrapper>
      </ButtonContent>
    </StyledButton>
  );
};

function Login() {
  return (
    <Container>
      <Box
        // backgroundColor="red"
        sx={{
          //   color: "#fff",
          width: "50%",
          // position: 'absolute',
          // top: '50%',           
          // left: '50%',          
          // transform: 'translate(-50%, -50%)',
          margin:"0 auto",
          boxShadow: "0px 0px 45px rgba(0, 0, 0, 0.05)",
          textAlign: "center",
          "@media (max-width: 480px)": {
            width: "100%",
            
        },
        "@media (max-width: 768px)": {
          width: "80%",
          
      },
      "@media (max-width: 1023px)": {
        width: "80%",
        
    }
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            width: "30%",
          }}
        >
          <img
            style={{ width: "100%", objectFit: "cover" }}
            src={logo}
            alt="Logo"
          />
        </Box>
        <h1   color="black"  style={styles.headingTypo}>
          Sign In
        </h1>
        <Box
          color="#000"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <AuthButton type="google" />
          <AuthButton type="facebook" />
          <AuthButton type="apple" />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
             sx={styles.typoLable}
              label="By clicking you agree to our Terms & Conditions and Privacy Policy"
            />
          </FormGroup>
        </Box>
      </Box>
      </Container>
  );
}

export default Login;
