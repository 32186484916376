import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Icon from "./Frame (1).png";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"

      >
    
        <Box
          sx={{
            // minWidth: "510px",
            maxWidth:"100%",
            height: "380px",
            filter: "dropShadow(0px 10px 40px rgba(0, 0, 0, 0.16)",
            '@media (max-width: 450px)': {
              minWidth: "300px", 
              height: "200px",
            },
          }}
        >
          <Box sx={{ width: "224px", height: "178px", margin: "0 auto" }}>
            <img src={Icon} sx={{ width: "224px", objectFit: "cover" }}></img>
          </Box>
          <Box sx={{ width: "80%", textAlign: "center", margin: "0 auto" }}>
            <Typography
              sx={{
                color: "#262626",
                fontFamily: "Nunito",
                fontSize: "35px",
                fontStyle: "normal",
                fontWeight: 800,
                marginTop: "1rem",
                lineHeight: "normal",
                // marginLeft: "2rem",
              }}
            >
              Purchase Successful !
            </Typography>
            <Typography
              sx={{
                color: "#636880",
                fontFamily: "Nunito",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                marginTop: "1rem",

                lineHeight: "20px",

                // marginLeft: "2rem",
              }}
            >
              You've successfully subscribed to our Teddy Tales plan.
            </Typography>

            <Button
              fullWidth
              variant="contained"
              sx={{ marginTop: "2rem", backgroundColor: "#00C6FF","&:hover":{
                backgroundColor: "#00C6FF"
              } }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
