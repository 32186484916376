import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Icon from "./logo.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function Logout() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth
        maxWidth='sm'
      >
        <Box
          sx={{
            // minWidth: "510px",
            height: "380px",

            filter: "dropShadow(0px 10px 40px rgba(0, 0, 0, 0.16)",
          }}
        >
          <Box sx={{ width: "200px", height: "178px", margin: "0 auto" }}>
            <img
              src={Icon}
              style={{ width: "180px", objectFit: "cover", marginTop: " 10% " }}
            ></img>
          </Box>

          <Box>
            <Typography
              sx={{
                color: "rgba(255, 11, 11, 0.87)",
                fontFamily: "Nunito",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 600,
                marginTop: "1rem",
                lineHeight: "normal",
                textAlign: "center",
              }}
            >
              Logout
            </Typography>
          </Box>
          <Box sx={{ width: "80%", textAlign: "center", margin: "0 auto" }}>
            <Typography
              sx={{
                color: "#636880",
                fontFamily: "Nunito",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                marginTop: "1rem",
                textTransform: "capitalize",
                lineHeight: "20px",
              }}
            >
              Are you sure you want to logout ?
            </Typography>
          
            <Box
              sx={{
                width:"100%",
                display: "flex",
                // flexDirection: "row",
                marginTop: "1rem",
                justifyContent: "space-between",
                gap:"20px"
              }}
            >
              <Button
                fullWidth
                variant="contained"
                sx={{
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  marginTop: "2rem",
                  backgroundColor: "#F2F2F2",
                  color:"black",
                  "&:hover": {
                    backgroundColor: "#00C6FF",
                    borderRadius: "5px",
                    color:"white",
                  },
                }}
              >
                No
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  marginTop: "2rem",
                  backgroundColor: "#F2F2F2",
                  color:"black",
                  "&:hover": {
                    backgroundColor: "#00C6FF",
                    borderRadius: "5px",
                    color:"white",
                  },
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
}