import * as React from "react";
import {
  Box,
  Grid,
  // Link,
  Typography,
  Container,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import logo from "./taddyIcon.png";
import playStore from "./playstore.png";
import { Link } from "react-router-dom";

// Replace these with your own social media URLs
const socialMediaLinks = {
  facebook: "facebook.com",
  twitter: "#",
  instagram: "#",
};

const style = {
  h: {
    color: "#262626",

    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    linHeight: "normal",
    textAlign: "center",
  },
};

const Footer = () => {
  return (
    <Container padding={64} margin={0}>
      <Box
        sx={{
          bgcolor: "background.paper",
          color: "text.secondary",

          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Container
          sx={{
            backgroundColor: "#FBC8DE",
            borderRadius: "20px",
            padding: "26px",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Box sx={{ height: "64%" }}>
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "9rem", height: "auto" }}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  //   variant="body1"
                  sx={{
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "28px",
                    color: "#262626",
                    lineHeight: "normal",
                  }}
                >
                  Subscribe To Our Newsletter!
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: "#636363",
                    lineHeight: "normal",
                    marginTop: "0rem",
                  }}
                >
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  maxHeight:"44px",
                  maxWidth:'399',
                }}
              >
                <input
                  type="text"
                  placeholder="Your email address"
                  style={{
                    border: "solid 0px",
                    borderRadius: "5px",
                    padding:'8px 12px  '
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#00C6FF",
                    marginLeft: "5px",
                    padding: "0 33px",
                    height:'100%',
                    fontSize: "0.7rem",
                  }}
                >
                  Subscribe
                </Button>
              </Box>
            </Grid>

            <Grid item spacing={2} xs={12} sm={12} md={6}>
              <Grid item display={"flex"} flexDirection={"colomun"}>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    gutterBottom
                    fontSize="1.5rem"
                  >
                    Quick Links
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#575757"
                    gutterBottom
                    sx={{ fontSize: "1.18rem", fontWeight: "400" }}
                  >
                    <Link to="/termcondition">Terms & Conditions</Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#575757"
                    gutterBottom
                    sx={{ fontSize: "1.18rem", fontWeight: "400" }}
                  >
                    <Link to="/privacy">Privacy Policy</Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#575757"
                    gutterBottom
                    sx={{ fontSize: "1.18rem", fontWeight: "400" }}
                  >
                    <Link to="/">Testimonials</Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    gutterBottom
                    fontSize="1.5rem"
                  >
                    Support
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#575757"
                    gutterBottom
                    sx={{ fontSize: "1.18rem", fontWeight: "400" }}
                  >
                    <Link to="/contact">Contact Us</Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#575757"
                    gutterBottom
                    sx={{ fontSize: "1.18rem", fontWeight: "400" }}
                  >
                    <Link to="/faq">FAQs</Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    gutterBottom
                    fontSize="1.5rem"
                  >
                    Follow Us
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#575757"
                    gutterBottom
                    sx={{
                      display: "flex",
                      fontSize: "1.18rem",
                      fontWeight: "400",
                    }}
                  >
                    <FacebookIcon />
                    <Link to='' >Facebook</Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#575757"
                    gutterBottom
                    sx={{
                      display: "flex",
                      fontSize: "1.18rem",
                      fontWeight: "400",
                    }}
                  >
                    <TwitterIcon />
                    <Link to="/contact">Twitter</Link>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#575757"
                    gutterBottom
                    sx={{
                      display: "flex",
                      fontSize: "1.18rem",
                      fontWeight: "400",
                    }}
                  >
                    <InstagramIcon /> Instagram
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#575757"
                    gutterBottom
                    sx={{
                      display: "flex",
                      fontSize: "1.18rem",
                      fontWeight: "400",
                    }}
                  >
                    <YouTubeIcon /> Youtube
                  </Typography>
                </Grid>
              </Grid>

              <Box
                flexDirection={"column"}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  marginTop: "15px",
                }}
              >
                <Typography
                  variant="body5"
                  color="#575757"
                  gutterBottom
                  sx={{ color: "#262626", marginRight: `calc(15% + 112px)` }}
                >
                  Get the App
                </Typography>
                <img
                  src={playStore}
                  style={{
                    height: "6rem ",
                    marginRight: "15%",
                    maxWidth15: "157px",
                  }}
                  alt="playstore"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography sx={style.h}>
          © 2024 Teddy Tale | All Right Reserved
        </Typography>
      </Box>
    </Container>
  );
};

export default Footer;
