import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/home/Home";
import DiscoveryAndCoaches from "./pages/discoveryCoaches";
import MainLayout from "./layout/MainLayout";
import Contact from "./pages/Contact/Contact";
import Slider1 from "./pages/Slider/Slider1";
import Subscrption from "./pages/Subscrption/Subscrption";
import FrequntQues from "./pages/FrequntQues/FrequntQues";
import Cookies from "./pages/Cookies/Cookies";
import CustomizedDialogs from "./components/Purchased/Purchased";
import AcceptCookies from "./components/AcceptCookies/AcceptCookies";
import PaymentDialog from "./components/PaymentDialog/PaymentDialog";
import Purchased from "./components/Purchased/Purchased";
import Logout from "./pages/Logout/Logout";
import SubcrptionHistory from "./components/SubcrptionHistory/SubcrptionHistory";
import Footer from "./components/Footer/Footer";
import Header from "./components/header/Header";
import About from "./pages/About/About";
import Login from "./pages/Login/Login";
import TermCondition from "./pages/TermCondition/TermCondition";
import Privacy from "./pages/Privacy/Privacy";
import LandingPage from "./pages/LandingPage/LandingPage";

function App() {
  return (
    <div className="App" >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />} >
          <Route index element={<Home />} />
          <Route path="/discovery" element={<DiscoveryAndCoaches />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FrequntQues />} />
          <Route path="/subscription" element={<Subscrption />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/termcondition" element={<TermCondition/>} />
          <Route path="/privacy" element={<Privacy/>} />
          
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <LandingPage/> */}
    </div>
  );
}

export default App;
