import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import Icon from "./image_2024_01_22T05_14_21_486Z.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AcceptCookies({ open, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
      >
        <Box
          sx={{
            // width: "700px",
            height: "550px",

            filter: "dropShadow(0px 10px 40px rgba(0, 0, 0, 0.16)",
          }}
        >
          <Box sx={{ width: "128px", height: "128px", marginLeft: "1rem" }}>
            <img src={Icon} sx={{ width: "128px", objectFit: "cover" }}></img>
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: "95%", margin: " 0 auto" }}>
            <Typography
              sx={{
                color: "#262626",
                fontFamily: "Nunito",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 800,
                // marginTop: "1rem",
                lineHeight: "normal",
                // marginLeft: "2rem",
              }}
            >
              We Value Your Privacy
            </Typography>
            <Typography
              sx={{
                color: "#636880",
                fontFamily: "Nunito",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                marginTop: "1rem",

                lineHeight: "20px",

                // marginLeft: "2rem",
              }}
            >
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used as a placeholder before final copy is available.In
              publishing and graphic design, Lorem ipsum is a placeholder text
              commonly used to demonstrate the visual form of a document or a
              typeface without relying on meaningful content. Lorem ipsum may be
              used as a placeholder before final copy is available. In
              publishing and graphic design, Lorem ipsum is a placeholder text
              commonly used to demonstrate the visual form of a document or a
              typeface without relying on meaningful content. Lorem ipsum may be
              used as a placeholder before final copy is available.In publishing
              and graphic design, Lorem ipsum is a placeholder text commonly
              used to demonstrate the visual form of a document or a typeface
              without relying on meaningful content. Lorem ipsum may be used as
              a placeholder before final copy is available.{" "}
            </Typography>

            <Box sx={{ marginTop: "1rem" }}>
              <Button
                size="small"
                sx={{
                  color: "#00C6FF",
                  fontFamily: "Nunito",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                }}
              >
                Term & Condition
              </Button>
              <Button
                size="small"
                sx={{
                  color: "#00C6FF",
                  fontFamily: "Nunito",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                }}
              >
                Privacy Policy
              </Button>
              <Button
                size="small"
                sx={{
                  color: "#00C6FF",
                  fontFamily: "Nunito",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                }}
              >
                More Information
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Button
                sx={{
                  width: "48%",
                  color: "#00C6FF",
                  fontFamily: "Nunito",
                  borderColor:"#00C6FF",
                  "&:hover":{
                    borderColor:"#00C6FF",

                  },
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  borderRadius:"30px",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}
                variant="outlined"
                onClick={handleClose}
              >
                denny cookies
              </Button>
              <Button
                sx={{
                  width: "48%",
                  color: "#fff",
                  backgroundColor:"#00C6FF",
                  "&:hover":{
                    backgroundColor:"#00C6FF"
                  },
                  fontFamily: "Nunito",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",

                  textTransform: "capitalize",
                }}
                variant="contained"
                onClick={handleClose}
              >
                Accept Cookies
              </Button>
            </Box>
          </Box>
        </Box>
      </BootstrapDialog>
    </React.Fragment>
  );
}
