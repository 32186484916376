import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "./taddyIcon.png";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

// import OpenDailog from '../OpenDailog'

const pages = [
  { name: "Subscriptions", path: "/subscription" },
  { name: "Contact us", path: "/contact" },
  { name: "Testimonials", path: "#testimonial" },
  { name: "FAQs", path: "/faq" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];
const style = {
  button: {
    color: "#000048",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase",
    my: 2,
    display: "block",
    color: "#000048",
  },
};
function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePageClick = (page) => {
    if (page.path === "#testimonial") {
      if (location.pathname === "/") {
        scrollToTestimonials();
      } else {
        navigate("/", { state: { scrollToTestimonials: true } });
      }
    } else {
      // Normal navigation for other links
      navigate(page.path);
    }
  };

  const scrollToTestimonials = () => {
    const testimonialsSection = document.getElementById("testimonial");
    if (testimonialsSection) {
      testimonialsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  React.useEffect(() => {
    if (location.state?.scrollToTestimonials) {
      scrollToTestimonials();
    }
  }, [location]);

  return (
    <AppBar position="static" sx={{ background: "white", color: "black" }}>
      <Container maxWidth="xl" sx={{ background: "white", color: "black" }}>
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Box
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
            }}
          >
                <Link to="/">
              <img src={logo} alt="logo" height={72} width={72} />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page.name}</Typography>{" "}
                  {/* Fix here */}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {/* <img src={logo} alt="logo" height={72} width={72}></img> */}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Link
                to={page.path} // Set the path for the Link component
                key={page.name}
                style={{ textDecoration: "none" }}
              >
                <Button sx={style.button} onClick={() => handlePageClick(page)}>
                  {page.name}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {/* <OpenDailog/> */}

            {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
            <Link to="/signin" style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  backgroundColor: "#00C6FF",
                  color: "#fff",
                  padding: "10px 31px",
                  "&:hover": {
                    backgroundColor: "#00C6FF",
                  },
                }}
              >
                Sign In
              </Button>
            </Link>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
