import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { Box, Container } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
const FrequntQues = () => {
  const styles = {
    heading: {
      color: "#262626",
      fontFamily: "Nunito",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 800,
      linHeight: " normal",
      textAlign: "center",
      marginTop: "2rem",
      marginBottom: "2rem",
    },
    AccodianSummary: {
      color: "#262626",
      fontFamily: "Nunito",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      linHeight: "20px",
    //   borderTop: "1px solid #e0e0e0", // Top border
    //   borderBottom: "1px solid #e0e0e0", // Bottom border
    },
    AccrodianDetails: {
      color: "#636880",
      fontFamily: "Nunito",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      linHeight: "20px",
      marginLeft:"2rem"
    },
    iconStyle: {
      color: '#00C6FF', // Define your color here
    },
  };
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  return (
    <Container maxWidth="lg">
      <Box>
        <Typography sx={styles.heading}> Frequently Asked Question</Typography>
      </Box>
      {Array.from({ length: 8 }, (_, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index + 1}`}
          onChange={handleChange(`panel${index + 1}`)}
        >
          <AccordionSummary
            expandIcon={
              expanded === `panel${index + 1}` ? (
                <CloseIcon style={styles.iconStyle} />
              ) : (
                <AddIcon style={styles.iconStyle} />
              )
            }
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography sx={styles.AccodianSummary}>
              The most popular question
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AccrodianDetails}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. simply so that a dummy text of the gone.
            </Typography>
          </AccordionDetails>
          <AccordionDetails>
            <Typography sx={styles.AccrodianDetails}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. simply so that a dummy text of the gone. Lorem Ipsum is
              simply dummy text of the printing and typesetting industry.
            </Typography>
          </AccordionDetails>
          <AccordionDetails>
            <Typography sx={styles.AccrodianDetails}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. simply so that a dummy text of the gone.
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      
    </Container>
  );
};

export default FrequntQues;
