import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/material";

const About = () => {
    const styles = {
        heading: {
          color: "#262626",
          fontFamily: "Nunito",
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: 800,
          linHeight: " normal",
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
        },
        paragraph: {
          color: "#636880",
          fontFamily: "Nunito",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          marginLeft: "2rem",
        },
        paraBox: {
          marginTop: "1rem",
          marginBottom: "1rem",
        },
      };
  return (
    <Container maxWidth="lg">
    <Box>
      <Typography sx={styles.heading}>About Us</Typography>
      <Typography sx={styles.paragraph}>
        These Terms and Conditions (“Terms”, “Terms and Conditions”) govern
        your relationship with Fululizo mobile applications and web panels
        (the “Service”) operated by Integral Media Limited (“us”, “we”, or
        “our”).
      </Typography>
      <Box sx={styles.paraBox}>
        <Typography sx={styles.paragraph}>
          Please read these Terms and Conditions carefully before using our
          Fululizo mobile applications and web panels (the “Service”). Your
          access to and use of the Service is conditioned on your acceptance
          of and compliance with these Terms. These Terms apply to all
          visitors, users and others who access or use the Service.
        </Typography>
      </Box>
      <Box sx={styles.paraBox}>
        <Typography sx={styles.paragraph}>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service. Content
        </Typography>
      </Box>
      <Box sx={styles.paraBox}>
        <Typography sx={styles.paragraph}>
          Our Service allows you to post, link, store, share and otherwise
          make available certain information, text, graphics, videos, or other
          material (“Content”). You are responsible for the Content that you
          post to the Service, including its legality, reliability, and
          appropriateness.
        </Typography>
      </Box>
      <Box sx={styles.paraBox}>
        <Typography sx={styles.paragraph}>
          By posting Content to the Service, you grant us the right and
          license to use, modify, publicly perform, publicly display,
          reproduce, and distribute such Content on and through the Service.
          You retain any and all of your rights to any Content you submit,
          post or display on or through the Service and you are responsible
          for protecting those rights. You agree that this license includes
          the right for us to make your Content available to other users of
          the Service, who may also use your Content subject to these Terms.
        </Typography>
      </Box>
      <Box sx={styles.paraBox}>
        <Typography sx={styles.paragraph}>
          Our Service allows you to post, link, store, share and otherwise
          make available certain information, text, graphics, videos, or other
          material (“Content”). You are responsible for the Content that you
          post to the Service, including its legality, reliability, and
          appropriateness.
        </Typography>
      </Box>
      <Box sx={styles.paraBox}>
        <Typography sx={styles.paragraph}>
          By posting Content to the Service, you grant us the right and
          license to use, modify, publicly perform, publicly display,
          reproduce, and distribute such Content on and through the Service.
          You retain any and all of your rights to any Content you submit,
          post or display on or through the Service and you are responsible
          for protecting those rights. You agree that this license includes
          the right for us to make your Content available to other users of
          the Service, who may also use your Content subject to these Terms.
        </Typography>
      </Box>
      <Box sx={styles.paraBox}>
        <Typography sx={styles.paragraph}>
          You represent and warrant that: (i) the Content is yours (you own
          it) or you have the right to use it and grant us the rights and
          license as provided in these Terms, and (ii) the posting of your
          Content on or through the Service does not violate the privacy
          rights, publicity rights, copyrights, contract rights or any other
          rights of any person. Accounts
        </Typography>
      </Box>
      <Box sx={styles.paraBox}>
        <Typography sx={styles.paragraph}>
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do
          so constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service. You are responsible for
          safeguarding the password that you use to access the Service and for
          any activities or actions under your password, whether your password
          is with our Service or a third-party service.
        </Typography>
      </Box>
      <Box sx={styles.paraBox}>
        <Typography sx={styles.paragraph}>
        You agree not to disclose your password to any third party. You must
      notify us immediately upon becoming aware of any breach of security or
      unauthorized use of your account.
        </Typography>
      </Box>
  
    </Box>
  </Container>
  )
}

export default About
