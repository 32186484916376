import { Container } from "@mui/material";
import React from "react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Slider from "react-slick";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";

const LeftArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "50%",
      left: "0",
      marginLeft: {sm:"-1.5rem",xs:"0rem"},
      transform: "translateY(-50%)",
      zIndex: 1,
    }}
  >
    <ArrowBackIcon />
  </IconButton>
);

const RightArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      top: "50%",
      right: "0",
      marginRight: {sm:"-2rem",xs:"0rem"},
      transform: "translateY(-50%)",
      // zIndex: 1,
    }}
  >
    <ArrowForwardIcon />
  </IconButton>
);
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const card = (
  <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Word of the Day
      </Typography>
      <Typography variant="h5" component="div">
        be{bull}nev{bull}o{bull}lent
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        adjective
      </Typography>
      <Typography variant="body2">
        well meaning and kindly.
        <br />
        {'"a benevolent smile"'}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions>
  </React.Fragment>
);


const CustomDots = ({ slidesToShow, totalSlides, currentSlide }) => {
  let dots = [];

  for (let i = 0; i < 3; i++) {
    let dotClass = currentSlide === i ? "slick-active" : "";
    dots.push(
      <li key={i} className={dotClass}>
        <button>{i + 1}</button>
      </li>
    );
  }

  return <ul className="slick-dots" style={{ display: "flex" }}>{dots}</ul>;
};
const Slider1 = () => {
  // var settings = {
  //   dots: true,
  //   // centerMode: true,
  //   // centerPadding: "60px",
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   prevArrow: <LeftArrow />,
  //   nextArrow: <RightArrow />,
  // };
  const state = {
    display: true,
    width: 600
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    //  centerMode: true,
    // centerPadding: "60px",
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          // dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <Box>
    <Box sx={{width: {sm:"90%",xs:"100%"},margin:"0 auto"}}>
      <Slider {...settings}>
      {[...Array(7)].map((_, index) => (
          <Box key={index} sx={{ minWidth: 200 , width:"100%"}}> {/* Adjusted margin */}
            <Card variant="outlined">{card}</Card>
          </Box>
        ))}
      </Slider>
    </Box>
    
<Box card>

</Box>
    </Box>
  );
};

export default Slider1;
