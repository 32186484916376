import React from "react";
import Header from "../components/header/Header";
import FrequntQues from "../pages/FrequntQues/FrequntQues";
import Footer from "../components/Footer/Footer";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function MainLayout({ children }) {
  return (
  <Box>
    <Header/>
    <Outlet/>
    <Box marginTop={3}>
   <Footer/>
    </Box>
  </Box>
  );
}
