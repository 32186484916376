import React from "react";
import "./Index.scss";
import taddyTail from "./assets/teddytail.png";
import taddy from "./assets/taddy.png";
import union from "./assets/Union.png";
import mobile1 from "./assets/main-mobile.png";
import taddyIcon from "./assets/taddy-icon.png";
import wave from "./assets/bg2.png";
import { Carousel } from "./Carousel";
import playStore from './assets/playstore.png';
import star from './icons/star.svg'

export default function Home() {
  return (
    <div className="wrapper">
      <div className="main-container">
        {/* section 1st */}
        <div className="banner">
          <div className="left-side-banner">
            <img src={taddyTail} alt="taddy-tail" className="taddy-tail" />
            <h1>Discover Teddy Tale Wonders Around You</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>

          <div className="right-side-banner">

            {/* <img src={union} className="union" /> */}
            <div  className="union" >
              <p className="text">Hey, Buddy!<br></br> Want to hear a story?</p>
            </div>
            <img src={taddy} alt="taddy image" className="taddy-image" />
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* Second section */}
        <div className="playstoreheading">
          <h2>Download Now On</h2>
        </div>
        <div className="second-section">
          <div className="second-left">
                <img src={playStore} alt="playstore-icon" className=""></img>
          </div>
          <div className="second-right">
            <div className="rating">
              <h3>10 M+</h3>
              <p>Downloads</p>
            </div>
            <div className="rating">
              <h3>15 M+</h3>
              <p>Reviews</p>
            </div>
            <div className="rating">
              <h3><img src={star} alt="rating" style={{width:'20px'}}></img> 4.5</h3>
              <p>Ratings</p>
            </div>
          </div>
        </div>
        {/*third section  */}
        <div className="third-section">
          <h1>Start To Use Teddy Tales</h1>
          <br></br>
          <h3>TO REACH CHILDREN, CREATE THEIR KNOW WORLD WITH TEDDY FRIEND</h3>
          <br></br>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <br></br>
          <br></br>
        </div>
        {/* fourth section */}

        <div className="fourth-section">
          <div className="left-side-banner left-fourth-section">
            <img src={taddy} alt="taddy image" className="taddy-image" />
          </div>
          <div className="right-side-fouth-section">
            {/* <Card/> */}
            <div className="card">
              <h2>Downloading the App</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                tincidunt justo eget ultricies fringilla.
              </p>
            </div>

            <div className="card card2">
              <h2>Downloading the App</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                tincidunt justo eget ultricies fringilla.
              </p>
            </div>
            <div className="card">
              <h2>Downloading the App</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                tincidunt justo eget ultricies fringilla.
              </p>
            </div>
            <div className="card card2">
              <h2>Downloading the App</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                tincidunt justo eget ultricies fringilla.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* second-part */}
      <div className="part-second">
        <img src={mobile1} alt="mb1" className="mobile-main"></img>

        <div className="main-container">
          <div className="second-container">
            <div className="part-second-left">
              <div>
                <img src={taddyIcon} alt="taddyIcon"></img>
              </div>
              <h1>Unlocking Potential</h1>
              <h2>Key Features & Functions</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer
              </p>
              <div className="features-container">
                <div className="feature-card">
                  <p className="card-text">Feature - 1</p>
                </div>
                <div className="feature-card">
                  <p className="card-text">Feature - 2</p>
                </div>
                <div className="feature-card">
                  <p className="card-text">Feature - 3</p>
                </div>
                <div className="feature-card">
                  <p className="card-text">Feature - 4</p>
                </div>
              </div>
            </div>

            {/* <div className="part-second-right">
            </div> */}
          </div>
        </div>
      </div>

      <div className="main-container " id = "testimonial">
        <div className="testimonials">
          <h2>You Are The Center Of Our Universe</h2>
          <h1>Testimonials</h1>
        </div>
        <Carousel />
      </div>
    </div>
  );
}
