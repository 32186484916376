import { Stack, Box, Typography, Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useState } from "react";
import SubcrptionHistory from "../../components/SubcrptionHistory/SubcrptionHistory";
import DoneIcon from "@mui/icons-material/Done";

// rest of your component code

const Subscrption = () => {
  const [isClicked, setIsClicked] = useState({
    free: false,
    basic: false,
    premium: false,
  });
  const styles = {
    checkbox: {
      "& .MuiSvgIcon-root": {
        color: "#00C6FF", // Change this to your desired color
      },
    },
    card: {
      width: "320px",
      minHeight: "700px",
      backgroundColor: "#FCAF63",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      border: "1px solid #FFF",
      background: "rgba(255, 255, 255, 0.05)",
      borderRadius: "11.667px",
      "&:hover": {
        backgroundColor: "#fff",
        "& .featureWrapper": {
          backgroundColor: "#00C6FF",
        },
      },
      //   justifyContent: "center",
      //   padding: "20px",
    },
    headerTypo1: {
      color: "#262626",
      textAlign: "center",
      fontFamily: "Nunito",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 800,
      lineeHight: "70px",
      textTransform: "uppercase",
    },
    typoSubTitle: {
      color: "#262626",
      textAlign: "center",
      fontFamily: "Calibri",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "400",
      lineeHight: "30px",
    },
    circleWrapper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "1rem",
    },
    circle: {
      width: "100px",
      height: "100px",
      borderRadius: "50%",
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    typoCircle: {
      color: "#262626",
      textAlign: "center",
      fontFamily: "Nunito",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 800,
      lineeHight: "normal",
    },
    // typoSubscrption: {
    //   color: "#262626",
    //   textAlign: "center",
    //   fontFamily: "Nunito",
    //   fontSize: "30px",
    //   fontStyle: "normal",
    //   fontWeight: 800,
    //   lineeHight: "normal",
    // },
    typoSubscrptionDetalis: {
      color: "#262626",
      fontFamily: "Nunito",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineeHight: "22px",
    },
    featureWrapper: {
      width: "100%",
      marginTop: "2rem",
      backgroundColor: " rgba(255, 255, 255, 0.15)",
      //   "&:hover":{
      //     backgroundColor:"#00C6FF"
      //   }
    },
    typofeatures: {
      color: "#262626",
      fontFamily: "Nunito",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineeHight: "27px",
    },

    typoLable: {
      // ... other styles
      display: "flex",
      alignItems: "center",

      // Target the label of the FormControlLabel
      color: "#262626",
      fontFamily: "Calibri",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "27px",
    },
    button: {
      borderRadius: "10px",
      // border: "1px solid #F9F7F7",
      background: "#00C6FF",
      "&:hover": {
        background: "#00C6FF",
      },
      width: "140px",
      height: "40px",
      fontSize:"12px",
    },
  };
  const customTheme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: "#000", // Change this to your desired color for the tick mark
            },
          },
        },
      },
    },
  });
  const handleButtonClick = (plan) => {
    setIsClicked({
      free: plan === "free",
      basic: plan === "basic",
      premium: plan === "premium",
    });
  };

  const isAnyPlanActive = () => {
    return isClicked.free || isClicked.basic || isClicked.premium;
  };

  const [monthlyChecked, setMonthlyChecked] = useState(false);
  const [yearlyChecked, setYearlyChecked] = useState(false);
  
  const [monthlyCheckedP, setMonthlyCheckedP] = useState(false);
  const [yearlyCheckedP, setYearlyCheckedP] = useState(false);

  const handleMonthlyChange = () => {
    setMonthlyChecked(true);
    setYearlyChecked(false);
  };

  const handleYearlyChange = () => {
    setMonthlyChecked(false);
    setYearlyChecked(true);
  };

  const handleMonthlyChangeP = () => {
    setMonthlyCheckedP(true);
    setYearlyCheckedP(false);
  };

  const handleYearlyChangeP = () => {
    setMonthlyCheckedP(false);
    setYearlyCheckedP(true);
  };
  return (
    <>
      <Box backgroundColor="#FCAF63">
        <Box textAlign="center" marginBottom="2rem">
          <Typography variant="h2" sx={styles.headerTypo1}>
            Subscrption Plans
          </Typography>
          <Typography variant="h5" sx={styles.typoSubTitle}>
            Choose The Plan That Sutis For You
          </Typography>
        </Box>
        <Stack
          justifyContent="center"
          alignItems="center"
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          flexWrap="wrap"
          useFlexGap
          paddingBottom={10}
        >
          <Box sx={styles.card}>
            {isClicked.free && (
              <Box
                width="100%"
                border="1px solid #1CC600"
                borderRadius="11.667px 11.667px 0 0"
              >
                <Typography
                  variant="h4"
                  backgroundColor="#1CC600"
                  borderRadius="11.667px 11.667px 0 0"
                  padding="1rem"
                >
                  Active Plan
                </Typography>
              </Box>
            )}
            <Box sx={styles.circleWrapper}>
              <Box sx={styles.circle}>
                <Typography variant="h4" sx={styles.typoCircle}>
                  $00
                </Typography>
              </Box>
            </Box>
            <Box width="100%" marginTop="1rem">
              <Typography variant="h2" sx={styles.typoCircle}>
                Free
              </Typography>
            </Box>

            <Box sx={{ width: "85%", textAlign: "justify" }}>
              <Typography variant="body1" sx={styles.typoSubscrptionDetalis}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever.
              </Typography>
            </Box>

            <Box className="featureWrapper" sx={styles.featureWrapper}>
              <Box sx={{ marginLeft: "2rem", marginTop: "1rem" }}>
                <Typography variant="h5" sx={styles.typofeatures}>
                  Features
                </Typography>
                <FormGroup sx={{ marginTop: "0.5rem" }}>
                  <Box sx={styles.typoLable}>
                    <DoneIcon sx={{ marginRight: "8px" }} />
                    {/* Tick Icon */}
                    <Typography sx={styles.typoLable}>
                      Preloaded Stories
                    </Typography>
                  </Box>
                </FormGroup>
              </Box>
            </Box>

            <Box sx={{ marginTop: "3rem" }}>
              {!isClicked.free && !isAnyPlanActive() && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={styles.button}
                  onClick={() => handleButtonClick("free")}
                >
                  Get Started
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={styles.card}>
            {isClicked.basic && (
              <Box
                width="100%"
                border="1px solid #1CC600"
                borderRadius="11.667px 11.667px 0 0"
              >
                <Typography
                  variant="h4"
                  backgroundColor="#1CC600"
                  borderRadius="11.667px 11.667px 0 0"
                  padding="1rem"
                >
                  Active Plan
                </Typography>
              </Box>
            )}
            <Box sx={styles.circleWrapper}>
              <Box sx={styles.circle}>
                <Typography variant="h4" sx={styles.typoCircle}>
                  $10
                </Typography>
              </Box>
            </Box>
            <Box width="100%" marginTop="1rem">
              <Typography variant="h2" sx={styles.typoCircle}>
                Basic
              </Typography>
            </Box>
            <Box sx={{ width: "85%", textAlign: "justify" }}>
              <Typography variant="body1" sx={styles.typoSubscrptionDetalis}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever.
              </Typography>
            </Box>
            <Box className="featureWrapper" sx={styles.featureWrapper}>
              <Box sx={{ marginLeft: "2rem", marginTop: "1rem" }}>
                <Typography variant="h5" sx={styles.typofeatures}>
                  Features
                </Typography>
                <FormGroup sx={{ marginTop: "0.5rem" }}>
                  <Box sx={styles.typoLable}>
                    <DoneIcon sx={{ marginRight: "8px" }} />
                    {/* Tick Icon */}
                    <Typography sx={styles.typoLable}>
                      Preloaded Stories
                    </Typography>
                  </Box>
                  <Box sx={styles.typoLable}>
                    <DoneIcon sx={{ marginRight: "8px" }} />
                    {/* Tick Icon */}
                    <Typography sx={styles.typoLable}>
                      15 Stories from CharGpt
                    </Typography>
                  </Box>
                </FormGroup>
              </Box>
            </Box>
            <Box sx={{ width: "100%", height: "30%", marginTop: "1rem" }}>
              <Box sx={{ marginLeft: "2rem", marginTop: "1rem" }}>
                <Typography variant="h5">For:</Typography>
                <FormGroup sx={{ marginTop: "0.5rem" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={styles.checkbox}
                        checked={monthlyChecked}
                        onChange={handleMonthlyChange}
                      />
                    }
                    label="Monthly"
                    sx={styles.typoLable}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={styles.checkbox}
                        checked={yearlyChecked}
                        onChange={handleYearlyChange}
                      />
                    }
                    label="Yearly"
                    sx={styles.typoLable}
                  />
                </FormGroup>
              </Box>
            </Box>
            <Box sx={{ marginTop: "3rem" }}>
              {!isClicked.basic && !isAnyPlanActive() && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={styles.button}
                  onClick={() => handleButtonClick("basic")}
                >
                  Get Started
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={styles.card}>
            {isClicked.premium && (
              <Box width="100%" borderRadius="11.667px 11.667px 0 0">
                <Typography
                  variant="h4"
                  backgroundColor="#1CC600"
                  borderRadius="11.667px 11.667px 0 0"
                  padding="1rem"
                >
                  Active Plan
                </Typography>
              </Box>
            )}
            <Box sx={styles.circleWrapper}>
              <Box sx={styles.circle}>
                <Typography variant="h4" sx={styles.typoCircle}>
                  $20
                </Typography>
              </Box>
            </Box>
            <Box width="100%" marginTop="1rem">
              <Typography variant="h2" sx={styles.typoCircle}>
                Premium
              </Typography>
            </Box>
            <Box sx={{ width: "85%", textAlign: "justify" }}>
              <Typography variant="body1" sx={styles.typoSubscrptionDetalis}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever.
              </Typography>
            </Box>
            <Box className="featureWrapper" sx={styles.featureWrapper}>
              <Box sx={{ marginLeft: "2rem", marginTop: "1rem" }}>
                <Typography variant="h5" sx={styles.typofeatures}>
                  Features
                </Typography>
                <FormGroup sx={{ marginTop: "0.5rem" }}>
                  <Box sx={styles.typoLable}>
                    <DoneIcon sx={{ marginRight: "8px" }} />
                    {/* Tick Icon */}
                    <Typography sx={styles.typoLable}>
                      Preloaded Stories
                    </Typography>
                  </Box>
                  <Box sx={styles.typoLable}>
                    <DoneIcon sx={{ marginRight: "8px" }} />
                    {/* Tick Icon */}
                    <Typography sx={styles.typoLable}>
                      Unlimited stroies from CharGpt
                    </Typography>
                  </Box>
                </FormGroup>
              </Box>
            </Box>
            <Box sx={{ width: "100%", height: "30%", marginTop: "1rem" }}>
              <Box sx={{ marginLeft: "2rem", marginTop: "1rem" }}>
                <Typography variant="h5">For:</Typography>
                <FormGroup sx={{ marginTop: "0.5rem" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={styles.checkbox}
                        checked={monthlyCheckedP}
                        onChange={handleMonthlyChangeP}
                      />
                    }
                    label="Monthly"
                    sx={styles.typoLable}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={styles.checkbox}
                        checked={yearlyCheckedP}
                        onChange={handleYearlyChangeP}
                      />
                    }
                    label="Yearly"
                    sx={styles.typoLable}
                  />
                </FormGroup>
              </Box>
            </Box>
            <Box sx={{ marginTop: "3rem" }}>
              {!isClicked.premium && !isAnyPlanActive() && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={styles.button}
                  onClick={() => handleButtonClick("premium")}
                >
                  Get Started
                </Button>
              )}
            </Box>
          </Box>
        </Stack>
      </Box>
      {isAnyPlanActive() && (
        <Box marginTop="2rem">
          <SubcrptionHistory />
        </Box>
      )}
    </>
  );
};

export default Subscrption;
